import nuxtStorage from 'nuxt-storage'

export function isSize(size: any) {
    if (size === 'half-width' ) {
        return 'w-full !md:w-[calc(50%-7px)]'


    } else  {
        return 'w-full'
    } 
    // else if (size == 'full-width' || size === 'large') {
    //     return 'w-full'
    // } 
}

export function getStorage (key:any, _default:any) {
    if (!nuxtStorage.localStorage) return _default
    return nuxtStorage.localStorage.getData(key)
}


export function checkTheTransitionCondition (inputData: any, comparisonData: any) {
    let arrayInputData = []
    let arrayComparisonData = []
    for (const key in inputData) {
        if (inputData.hasOwnProperty(key)) {
            const value = inputData[key]
            
            if(typeof(value) == 'object'  )
            {
                if( value.length > 0)
                {
                    arrayInputData.push(key)
                }
            }
            else
            {
                arrayInputData.push(key)
            }
        }
    }
    comparisonData.forEach((item: any) => {
        arrayComparisonData.push(item?.field_name)
    })

    return arrayComparisonData.every((element) => arrayInputData.includes(element))
}


export function saveMainDataLocal(data: any,option:any)  {
    const data_local = ref(getStorage('data_analysis', {}))
    if (data_local.value) {
        if(option)
        {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key]
                    data_local.value.MainData[key] = value
                }
            }
        }
        else 
        {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key]
                    data_local.value.SubData[key] = value
                }
            }
        }
        nuxtStorage.localStorage.setData('data_analysis', data_local.value, 168, 'h')

       
    } else {
        const dataSave = {  MainData: {},SubData:{} }
        if(option)
        {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key]
                    dataSave.MainData[key] = value
                }
            }
        }
        else 
        {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key]
                    dataSave.SubData[key] = value
                }
            }
        }
       
        nuxtStorage.localStorage.setData('data_analysis', dataSave, 168, 'h')
    }
}
